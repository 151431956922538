<template>
  <div>
    <!-- HEADER STRONY -->
    <div class="row bg-light mt-1 ml-0">
      <div class="col-6">
        <router-link to="/addpayment">
          <button style="width: 10%" class="btn btn-info mt-1 mb-1 float-left">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-cash-coin"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0z"
              />
              <path
                d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1h-.003zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195l.054.012z"
              />
              <path
                d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083c.058-.344.145-.678.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1H1z"
              />
              <path
                d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 5.982 5.982 0 0 1 3.13-1.567z"
              />
            </svg>
            <br />
            <!-- Dodaj -->
          </button>
        </router-link>
        <router-link to="/payments-costs">
          <button style="width: 15%" class="btn btn-info mt-1 mb-1 ml-3 float-left">
            <b>Koszty</b>
            <br />
            <!-- Dodaj -->
          </button>
        </router-link>
        <router-link to="/payments-due">
          <button style="width: 15%" class="btn btn-info mt-1 mb-1 ml-3 float-left">
            <b>Należności</b>
            <br />
            <!-- Dodaj -->
          </button>
        </router-link>
        <router-link to="/payments-archive">
          <button style="width: 15%" class="btn btn-info mt-1 mb-1 ml-3 float-left">
            <b>Zapłacone</b>
            <br />
            <!-- Dodaj -->
          </button>
        </router-link>
      </div>
      <!-- <div class="col-1">
        <input
          type="checkbox"
          id="payments"
          value="Nalezności"
          v-model="paymentsChecked"
        />
        <label class="ml-2 align-middle" for="payments">Naleznosci</label>
      </div>
      <div class="col-1">
        <input type="checkbox" id="costs" value="Koszty" v-model="costsChecked" />
        <label class="ml-2 align-middle" for="wydatki">Koszty</label>
      </div> -->

      <div class="col-4">
        <input
          v-model="search"
          class="form-control w-100 float-right form-control-sm mt-1 mr-3"
          type="search"
          placeholder="Wyszukaj kontrahenta"
          aria-label="Search"
        />
      </div>
    </div>

    <!-- TABLEA -->

    <table class="table">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col"></th>
          <th scope="col"></th>
          <th scope="col">Całkowita kwota kosztów:</th>
          <th>{{ allPaymentsValue }} PLN</th>
        </tr>
        <tr>
          <th scope="col">Typ</th>
          <th scope="col">Kontrahent</th>
          <th scope="col">Numer Faktury</th>
          <th scope="col">Data płatności</th>
          <th scope="col">Kwota</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          scope="row"
          v-for="payment in filteredPayments"
          :key="payment.id"
          :class="[
            payment.contractor.type,
            payment.date <= formatDate ? 'outOfDate' : '',
          ]"
        >
          <th scope="row">
            <div v-if="payment.contractor.type == 'company'">
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                class="bi bi-building"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M14.763.075A.5.5 0 0 1 15 .5v15a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5V14h-1v1.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V10a.5.5 0 0 1 .342-.474L6 7.64V4.5a.5.5 0 0 1 .276-.447l8-4a.5.5 0 0 1 .487.022zM6 8.694L1 10.36V15h5V8.694zM7 15h2v-1.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5V15h2V1.309l-7 3.5V15z"
                />
                <path
                  d="M2 11h1v1H2v-1zm2 0h1v1H4v-1zm-2 2h1v1H2v-1zm2 0h1v1H4v-1zm4-4h1v1H8V9zm2 0h1v1h-1V9zm-2 2h1v1H8v-1zm2 0h1v1h-1v-1zm2-2h1v1h-1V9zm0 2h1v1h-1v-1zM8 7h1v1H8V7zm2 0h1v1h-1V7zm2 0h1v1h-1V7zM8 5h1v1H8V5zm2 0h1v1h-1V5zm2 0h1v1h-1V5zm0-2h1v1h-1V3z"
                />
              </svg>
              | {{ payment.type }}
            </div>
            <div v-else>
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                class="bi bi-person"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 5a2 2 0 1 1-4 0 2 2 0 0 1 4 0zM8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm6 5c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"
                />
              </svg>
              | {{ payment.type }}
            </div>
          </th>
          <td>{{ payment.contractor.label }}</td>
          <td>{{ payment.invoiceNumber }}</td>
          <td>
            {{ payment.date }}
          </td>
          <td>{{ payment.price }} PLN</td>

          <td>
            <button
              class="btn btn-sm align-middle btn-info"
              @click="openPaymentModal(payment)"
            >
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 16 16"
                class="bi bi-file-earmark-arrow-up"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 0h5.5v1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h1V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2z"
                />
                <path d="M9.5 3V0L14 4.5h-3A1.5 1.5 0 0 1 9.5 3z" />
                <path
                  fill-rule="evenodd"
                  d="M8 12a.5.5 0 0 0 .5-.5V7.707l1.146 1.147a.5.5 0 0 0 .708-.708l-2-2a.5.5 0 0 0-.708 0l-2 2a.5.5 0 1 0 .708.708L7.5 7.707V11.5a.5.5 0 0 0 .5.5z"
                />
              </svg>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <payment-modal @closeModal="closeModal()" :payment="payment"></payment-modal>
    <!-- <div
      style="border: 1px solid red"
      v-for="payment in sortedPayments"
      :key="payment.id"
    >
      {{ payment }}
      <div style="border: 1px solid green">{{ payment.date }}</div>
    </div> -->
  </div>
</template>
<script>
import { mapState } from "vuex";
import PaymentModal from "../components/PaymentModal";

export default {
  data() {
    return {
      //   installation: {
      //     brand: "",
      //     model: "",
      //     plate: "",
      //     vin: "",
      //     year: "",
      //     imageUrl: "",
      //   },
      //   openForm: false,
      search: "",
      payment: "",
      costsChecked: false,
      paymentsChecked: false,
      phoneSearch: "",
      today: new Date("yyyy-mm-dd"),
    };
  },
  components: {
    "payment-modal": PaymentModal,
  },

  computed: {
    ...mapState(["userProfile", "payments"]),
    formatDate() {
      var d = new Date(),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },

    sortedPayments() {
      function compare(a, b) {
        return new Date(a.date) - new Date(b.date);
      }
      let arr = this.costsPayments;

      return arr.sort(compare);
    },

    costsPayments() {
      // console.log(this.payments.filter((i) => i.type === "Koszty"));
      return this.payments.filter((i) => i.type === "Koszty");
    },

    filteredPayments() {
      return this.sortedPayments.filter((item) => {
        if (item.contractor.label.toUpperCase().includes(this.search.toUpperCase())) {
          return item;
        } else if (
          item.contractor.phoneNumber.toUpperCase().includes(this.search.toUpperCase())
        ) {
          return item;
        } else if (item.invoiceNumber.toUpperCase().includes(this.search.toUpperCase())) {
          return item;
        } else if (
          item.contractor.email.toUpperCase().includes(this.search.toUpperCase())
        ) {
          return item;
        }
      });
    },
    allPaymentsValue() {
      let allCosts = 0;
      this.filteredPayments.forEach((element) => {
        let string = element.price.replace(",", ".");
        let num = parseFloat(string);
        allCosts += num;
      });
      return allCosts.toFixed(2);
    },
  },
  methods: {
    //    openInstallationModal(installation) {
    //   this.$modal.show("installation-modal");
    //   this.installation = installation;
    // },
    openPaymentModal(event) {
      console.log(this.formatDate);
      this.$modal.show("payment-modal");
      this.payment = event;
      // Prevent navigating to narrower view (default vue-cal behavior).
      // e.stopPropagation();
    },
    closeModal() {
      this.$modal.hide("payment-modal");
    },
  },
};
</script>
<style>
.customer {
  background-color: rgba(0, 123, 255, 0.1);
}
.company {
  background-color: rgba(23, 162, 184, 0.1);
}
.outOfDate {
  background-color: lightpink !important;
  font-weight: 900;
}
</style>
