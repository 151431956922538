<template>
  <modal
    name="payment-modal"
    :resizable="true"
    :height="'auto'"
    :width="'100%'"
    :scrollable="true"
  >
    <div class="row bg-secondary mt-1 ml-0">
      <div class="col-6">
        <h5
          v-if="payment.contractor.label != 'undefined'"
          class="float-left align-middle text-white mt-2"
        >
          {{ payment.contractor.label }}
        </h5>
      </div>
      <div class="col-md-6 col-sm-12">
        <div class="float-right">
          <button
            id="sendEmailButton"
            class="btn btn-sm btn-pill btn-warning my-1 mx-2"
            @click="send(payment)"
          >
            Wyślij mail z przypomnieniem o płatności
          </button>
          <button
            id="endPaymentButton"
            class="btn btn-sm btn-pill btn-success my-1 mx-2"
            @click="endPayment(payment)"
          >
            Dodaj płatność
          </button>
          <button
            id="editPaymentButton"
            class="btn btn-sm btn-pill btn-info my-1 mx-2"
            @click="updatePayment(payment)"
          >
            Edytuj
          </button>
          <button
            class="btn btn-sm btn-pill btn-danger my-1 mx-2"
            @click="$emit('closeModal')"
          >
            Zamknij
          </button>
        </div>
      </div>
    </div>
    <div id="modal-content" class="row mb-5">
      <div class="col-12 p-5">
        <h3>Dane kontrahenta:</h3>
        <div class="row">
          <table class="table table-striped table-sm">
            <tbody>
              <tr>
                <td>Nazwa:</td>
                <td>
                  <b> {{ payment.contractor.label }}</b>
                </td>
              </tr>
              <tr>
                <td>Adres:</td>
                <td>
                  <b> {{ payment.contractor.address }} </b>
                </td>
              </tr>
              <tr>
                <td>Numer telefonu:</td>
                <td>
                  <b
                    ><a
                      style="text-decoration: none; color: #000; cursor: pointer"
                      :href="`tel:${payment.contractor.phoneNumber}`"
                      >{{ payment.contractor.phoneNumber }}</a
                    ></b
                  >
                </td>
              </tr>
              <tr>
                <td>Email:</td>
                <td>
                  <b
                    ><a
                      style="text-decoration: none; color: #000; cursor: pointer"
                      :href="`mailto:${payment.contractor.email}`"
                      >{{ payment.contractor.email }}</a
                    ></b
                  >
                </td>
              </tr>
              <tr>
                <td>NIP:</td>
                <td>
                  <b> {{ payment.contractor.nip }} </b>
                </td>
              </tr>
              <tr>
                <td>Osoba Kontaktowa:</td>
                <td>
                  <b> {{ payment.contractor.contactPerson }} </b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <small>Typ płatności</small>
        <v-select
          v-model="payment.type"
          :options="['Nalezności', 'Koszty']"
          placeholder="Typ płatności"
        >
        </v-select>

        <div class="row w-100">
          <div class="col-sm-12 col-md-6">
            <small>Data płatności </small>
            <input
              v-model="payment.date"
              type="date"
              class="form-control mt-1 mb-1"
              placeholder="Data płatności"
            />
          </div>
          <div class="col-sm-12 col-md-6">
            <small>Kwota PLN</small>
            <input
              v-model="payment.price"
              type="text"
              class="form-control mt-1 mb-1"
              placeholder="Kwota"
            />
          </div>
        </div>

        <small>Uwagi</small>
        <vue-editor
          class="border"
          style="height: 350px; overflow: auto"
          v-model="payment.notice"
        ></vue-editor>
        <!-- <vue-editor
          v-else
          style="height: 350px; overflow: auto"
          v-model="payment.gówno"
        ></vue-editor> -->
        <!-- <div v-else class=border style="height:350px;overflow:auto;" v-html="payment.notice"></div> -->
        <!-- <div class="border">{{payment.payment}}</div> -->
        <!-- <textarea
          class="form-control my-1"
          v-model="payment.content"
          placeholder="Komentarz"
        /> -->
        <!-- <input class="form-control my-1" v-model="car.pt" placeholder="Przegląd techniczny" /> -->

        <!-- <div class="col-6">
        <div class="p-5">
     
        </div>
      </div> -->
        <!-- <div class="col-6">
         
      </div> -->
        <hr />
        <small style="cursor: pointer" @click="deletepayment(payment)">
          Usuń płatność tylko gdy jesteś tego pewny.
        </small>
      </div>
    </div>
  </modal>
</template>
<script src="https://smtpjs.com/v3/smtp.js"></script>
<script>
import { mapState } from "vuex";
import { VueEditor } from "vue2-editor";

export default {
  props: ["payment"],
  label: "",

  data() {
    return {
      imageUrl: "",
      consolelog: "test",
    };
  },
  components: {
    VueEditor,
  },
  computed: {
    ...mapState(["userProfile", "paymentUpdated"]),
  },

  created() {
    var scripts = ["https://smtpjs.com/v3/smtp.js"];
    scripts.forEach((script) => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
    });
  },

  methods: {
    send(payment) {
      Email.send({
        SecureToken: "edd158ac-b4c0-46b9-8986-d78583d5ce84",
        // Host: "serwer1984787.home.pl",
        // Username: "no-reply-klimbud@2qbs.pl",
        // Password: "y9tTLZp_K",
        To: payment.contractor.email,
        Cc: "biuro@klim-bud.pl",
        From: "no-reply-klimbud@2qbs.pl",
        Subject: "Przypomnienie o terminie płatności faktury " + payment.invoiceNumber,
        Body:
          "<h2> Przypominamy o nieopłaconej fakturze nr. " +
          payment.invoiceNumber +
          " </h2>" +
          "<div>" +
          "Kwota: " +
          payment.price +
          "<br/>" +
          "Data płatności: " +
          payment.date +
          "<br>" +
          "<br>" +
          "<small>Wiadomość została wygenerowana automatycznie, prosimy na nią nie odpowiadać.</small>" +
          "</div>" +
          "<br>" +
          "<hr>" +
          "<div>" +
          "<br>" +
          "<br>" +
          "<img src='https://klim-bud.pl/wp-content/uploads/2020/07/LOGO_KLIM-BUD.png'>" +
          "<br>" +
          "<br>" +
          "F.H.U Klim-Bud" +
          "<br>" +
          "Wąska 4" +
          "<br>" +
          "43-520 Chybie" +
          "<br>" +
          "tel. 504 041 946" +
          "<br>" +
          "<a href='mailto:biuro@klim-bud.pl'>biuro@klim-bud.pl</a> " +
          "<br>" +
          "<a href='https://klim-bud.pl' target='_blank'>www.klim-bud.pl</a>",
      }).then(() => alert("Przypomnienie zostało wysłane"));
    },

    updatePayment(payment) {
      // console.log(payment.id);
      this.$store
        .dispatch("updatePayment", {
          id: payment.id,
          notice: payment.notice,
          price: payment.price,
          date: payment.date,
          type: payment.type,
        })
        .then(() => {
          if (this.paymentUpdated) {
            let button = document.getElementById("editPaymentButton");
            button.style.backgroundColor = "#409855";
            button.style.color = "#17801b";
            button.innerText = "Płatność została edytowana";
          }
          setTimeout(() => {
            let button = document.getElementById("editPaymentButton");
            button.style.backgroundColor = "#409855";
            button.style.color = "";
            button.innerText = "Płatność została edytowana";
            this.carUpdated = null;
          }, 200);
          setTimeout(() => {
            this.$emit("closeModal");
          }, 2000);
        });
    },

    endPayment(payment) {
      this.$store
        .dispatch("endPayment", {
          id: payment.id,
          status: "end",
        })
        .then(() => {
          if (this.paymentUpdated) {
            let button = document.getElementById("endPaymentButton");
            button.style.backgroundColor = "#409855";
            button.style.color = "#17801b";
            button.innerText = "Płatność została dodana";
          }
          setTimeout(() => {
            let button = document.getElementById("endPaymentButton");
            button.style.backgroundColor = "#409855";
            button.style.color = "";
            button.innerText = "Płatność została dodana";
            this.carUpdated = null;
          }, 200);
          setTimeout(() => {
            this.$emit("closeModal");
          }, 3000);
        });
    },

    deletepayment(payment) {
      if (confirm("Czy jesteś pewien ze chcesz usunąć płatność?")) {
        this.$store.dispatch("deletepayment", {
          id: payment.id,
        });

        setTimeout(() => {
          this.$emit("closeModal");
        }, 200);
        console.log("payment Deleted.");
      }
    },
  },
};
</script>
<style></style>
